/* responsiveUsp */
define(['$console', '$window'], ($console, $window) => () => {
  const AUTOMOVE_TIMEOUT = 3000;
  const INTERACTION_TIMEOUT = 5000;

  const _touch = {
    startX: 0,
    history: [],
    historyMaxLength: 5,
    threshold: 1
  };

  const comp = {
    element: null,
    slider: null,
    links: [],
    nav: null,
    navDots: [],
    current: -1,
    timeout: null,
    // testing
    _touch
  };

  const _classes = {
    navDot: 'responsiveUsp_navDot',
    navDotActive: 'responsiveUsp_navDot-active',
    sliderTransitioned: 'responsiveUsp_slider-transitioned'
  };

  const _select = {
    slider: '.responsiveUsp_slider',
    link: '.responsiveUsp_link',
    nav: '.responsiveUsp_nav'
  };

  /**
   * Init
   *
   * @param {HTMLElement} element
   */
  comp.init = function(element) {
    comp.element = element;
    comp.slider = element.querySelector(_select.slider);
    comp.nav = element.querySelector(_select.nav);
    comp.links = [...element.querySelectorAll(_select.link)];

    comp.build();
    comp.bind();
    comp.automove(true);
  };

  /**
   * Builds nav.
   */
  comp.build = function() {
    if(comp.links.length > 1) {
      comp.navDots = comp.links.map(() => {
        const el = document.createElement('div');
        el.className = _classes.navDot;

        comp.nav.appendChild(el);

        return el;
      });
    }
  };

  /**
   * Binds touch event listeners to the slider.
   */
  comp.bind = function() {
    comp.slider.addEventListener('touchstart', comp.touchstart, {passive: true});
    comp.slider.addEventListener('touchmove', comp.touchmove, {passive: true});
    comp.slider.addEventListener('touchend', comp.touchend, {passive: true});
  };

  /**
   * Navigates to specified index.
   *
   * @param {Number} index
   */
  comp.navigate = function(index) {
    if (comp.navDots.length > 1) {
      if (comp.navDots[comp.current]) {
        comp.navDots[comp.current].classList.remove(_classes.navDotActive);
      }

      comp.current = index;

      if (comp.navDots[comp.current]) {
        comp.navDots[index].classList.add(_classes.navDotActive);
        if (document.dir === "rtl") {
          comp.slider.style.transform = `translateX(${100 * index}%)`;
        } else {
          comp.slider.style.transform = `translateX(${-100 * index}%)`;
        }
      } else {
        $console.error(`No child at specified index ${index}.`);
      }
    }
  };

  /**
   * Navigates to previous slide.
   */
  comp.previous = function(isInteraction) {
    const index = comp.current - 1;

    if (index >= 0) {
      comp.navigate(index);
    } else {
      comp.navigate(isInteraction ? index + 1 : comp.links.length - 1);
    }
  };

  /**
   * Navigates to next slide.
   */
  comp.next = function(isInteraction) {
    const index = comp.current + 1;

    if (index < comp.links.length) {
      comp.navigate(index);
    } else {
      comp.navigate(isInteraction ? index - 1 : 0);
    }
  };

  comp.automove = function(reset) {
    if (reset) {
      // this is set to -1 so the next comp.next navigates to 0
      comp.current = -1;
    }

    comp.next(false);
    comp.timeout = $window.setTimeout(comp.automove, AUTOMOVE_TIMEOUT);
  };

  /**
   * Touch start event listener
   *
   * @param {TouchEvent} ev
   */
  comp.touchstart = function(ev) {
    const x = ev.changedTouches[0].pageX;
    const t = ev.timeStamp;

    _touch.startX = x;
    _touch.history = [{ x, t }];

    $window.clearTimeout(comp.timeout);
    comp.slider.classList.remove(_classes.sliderTransitioned);
  };

  /**
   * Touch move event listener
   *
   * @param {TouchEvent} ev
   */
  comp.touchmove = function(ev) {
    const x = ev.changedTouches[0].pageX;
    const t = ev.timeStamp;

    _touch.history.push({ x, t });

    if (_touch.history.length > _touch.historyMaxLength) {
      _touch.history.shift();
    }
    if (document.dir === 'rtl') {
      comp.slider.style.transform = `translateX(${100 * comp.current}%) translateX(${x - _touch.startX}px)`;
    } else {
      comp.slider.style.transform = `translateX(${-100 * comp.current}%) translateX(${x - _touch.startX}px)`;
    }

    // safari fix
    ev.preventDefault();
  };

  /**
   * Touch env event listener
   *
   * @param {TouchEvent} ev
   */
  comp.touchend = function(ev) {
    comp.timeout = $window.setTimeout(comp.automove, INTERACTION_TIMEOUT);
    comp.slider.classList.add(_classes.sliderTransitioned);

    const x = ev.changedTouches[0].pageX;
    const diff = x - _touch.startX;

    if (Math.abs(diff) >= comp.slider.offsetWidth / 2) {
      // deferred so transition takes effect
      $window.setImmediate
        ? $window.setImmediate(comp.move(diff, true))
        : $window.setTimeout(comp.move(diff, true), 0);
      return;
    }

    // Last touch is unreliable, don't push to history
    var velX = 0;

    for (var index = 1; index < _touch.history.length; index++) {
      var deltaX = _touch.history[index].x - _touch.history[index - 1].x;
      var deltaT = _touch.history[index].t - _touch.history[index - 1].t;

      velX += deltaX / deltaT;
    }

    if (Math.abs(velX) < _touch.threshold) {
      comp.navigate(comp.current);
      return;
    }

    // deferred so transition takes effect
    $window.setImmediate
      ? $window.setImmediate(comp.move(velX, true))
      : $window.setTimeout(comp.move(velX, true), 0);
  };

  /**
   * Return a function that moves the slider left/right according to the sign of the parameter.
   *
   * @param {Number} diff
   */
  comp.move = (diff, isInteraction) => () => {
    if (diff > 0) {
      comp.previous(isInteraction);
    } else {
      comp.next(isInteraction);
    }
  };

  return comp;
});
